import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8124f45c = () => interopDefault(import('../pages/abo/index.vue' /* webpackChunkName: "pages/abo/index" */))
const _7a891fc6 = () => interopDefault(import('../pages/agb.vue' /* webpackChunkName: "pages/agb" */))
const _7440bcee = () => interopDefault(import('../pages/benutzerkonto.vue' /* webpackChunkName: "pages/benutzerkonto" */))
const _6d0be233 = () => interopDefault(import('../pages/booklet.vue' /* webpackChunkName: "pages/booklet" */))
const _0619751c = () => interopDefault(import('../pages/cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _37fe8bc4 = () => interopDefault(import('../pages/datenschutz.vue' /* webpackChunkName: "pages/datenschutz" */))
const _581fa436 = () => interopDefault(import('../pages/epaper.vue' /* webpackChunkName: "pages/epaper" */))
const _126d7150 = () => interopDefault(import('../pages/impressum.vue' /* webpackChunkName: "pages/impressum" */))
const _5d925428 = () => interopDefault(import('../pages/newsletter.vue' /* webpackChunkName: "pages/newsletter" */))
const _79f37899 = () => interopDefault(import('../pages/offenlegung.vue' /* webpackChunkName: "pages/offenlegung" */))
const _76416171 = () => interopDefault(import('../pages/registered.vue' /* webpackChunkName: "pages/registered" */))
const _6271396e = () => interopDefault(import('../pages/subscribed.vue' /* webpackChunkName: "pages/subscribed" */))
const _5cb25372 = () => interopDefault(import('../pages/subscription-error.vue' /* webpackChunkName: "pages/subscription-error" */))
const _98e03242 = () => interopDefault(import('../pages/suche.vue' /* webpackChunkName: "pages/suche" */))
const _4d8b4ae6 = () => interopDefault(import('../pages/ueber-uns.vue' /* webpackChunkName: "pages/ueber-uns" */))
const _f8f4b45a = () => interopDefault(import('../pages/widerruf.vue' /* webpackChunkName: "pages/widerruf" */))
const _428ec6b3 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _849236ec = () => interopDefault(import('../pages/abo/_slug.vue' /* webpackChunkName: "pages/abo/_slug" */))
const _72e306e4 = () => interopDefault(import('../pages/archive/_id.vue' /* webpackChunkName: "pages/archive/_id" */))
const _c68e167c = () => interopDefault(import('../pages/article/_id.vue' /* webpackChunkName: "pages/article/_id" */))
const _6466fc25 = () => interopDefault(import('../pages/author/_id.vue' /* webpackChunkName: "pages/author/_id" */))
const _2c34e65f = () => interopDefault(import('../pages/channel/_slug.vue' /* webpackChunkName: "pages/channel/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/abo",
    component: _8124f45c,
    name: "abo"
  }, {
    path: "/agb",
    component: _7a891fc6,
    name: "agb"
  }, {
    path: "/benutzerkonto",
    component: _7440bcee,
    name: "benutzerkonto"
  }, {
    path: "/booklet",
    component: _6d0be233,
    name: "booklet"
  }, {
    path: "/cookie-policy",
    component: _0619751c,
    name: "cookie-policy"
  }, {
    path: "/datenschutz",
    component: _37fe8bc4,
    name: "datenschutz"
  }, {
    path: "/epaper",
    component: _581fa436,
    name: "epaper"
  }, {
    path: "/impressum",
    component: _126d7150,
    name: "impressum"
  }, {
    path: "/newsletter",
    component: _5d925428,
    name: "newsletter"
  }, {
    path: "/offenlegung",
    component: _79f37899,
    name: "offenlegung"
  }, {
    path: "/registered",
    component: _76416171,
    name: "registered"
  }, {
    path: "/subscribed",
    component: _6271396e,
    name: "subscribed"
  }, {
    path: "/subscription-error",
    component: _5cb25372,
    name: "subscription-error"
  }, {
    path: "/suche",
    component: _98e03242,
    name: "suche"
  }, {
    path: "/ueber-uns",
    component: _4d8b4ae6,
    name: "ueber-uns"
  }, {
    path: "/widerruf",
    component: _f8f4b45a,
    name: "widerruf"
  }, {
    path: "/",
    component: _428ec6b3,
    name: "index"
  }, {
    path: "/abo/:slug",
    component: _849236ec,
    name: "abo-slug"
  }, {
    path: "/archive/:id?",
    component: _72e306e4,
    name: "archive-id"
  }, {
    path: "/article/:id?",
    component: _c68e167c,
    name: "article-id"
  }, {
    path: "/author/:id?",
    component: _6466fc25,
    name: "author-id"
  }, {
    path: "/channel/:slug?",
    component: _2c34e65f,
    name: "channel-slug"
  }, {
    path: "/autor/:authorName([a-z0-9A-F%-]+)-:id(\\d+)",
    component: _6466fc25,
    name: "author-canonical"
  }, {
    path: "/:channelSlugs([a-z0-9A-F%-]+)+/:slug([a-z0-9A-F%-]+)-:id(\\d+)",
    component: _c68e167c,
    name: "article-canonical"
  }, {
    path: "/:channelSlugs([a-z0-9A-F%-]+)*/:slug([a-z0-9A-F%-]+)",
    component: _2c34e65f,
    name: "channel-canonical"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
